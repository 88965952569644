/* styles.css */
:root {
  --text-color: #000; /* Default to black for light mode */
  --background-color: #fff; /* Default to white for light mode */
}

.form-item {
  color: var(--text-color);
  background-color: var(--background-color);
}

.icon-color {
  color: var(--text-color); /* Ensure icons use the same text color */
}

.form-item input,
.form-item label,
.form-item .MuiInputBase-root,
.form-item .MuiFormLabel-root {
  color: var(--text-color) !important;
}

.form-item .MuiOutlinedInput-root {
  background-color: var(--background-color) !important;
}

.form-item .MuiSelect-root {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}



/* Input, Select, and TextField Outline Styling */
.form-item .MuiSelect-root,
.form-item .MuiOutlinedInput-notchedOutline {
  border: 2px solid #b0b0b0; /* Light grey border */
  border-radius: 6px; /* Rounded edges for a refined look */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

/* Dark mode focus and hover effects */
.form-item input:focus,
.form-item .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.form-item .MuiSelect-root:hover,
.form-item .Mui-focused .MuiOutlinedInput-root {
  border-color: #b0b0b0; /* Same light grey for consistency */
  box-shadow: none; /* Remove glow for a clean look */
}

/* Adjust button focus and hover effects */
.form-item button.MuiButton-root:hover,
.form-item button.MuiButton-root:focus {
  outline: none;
  border: 1px solid #b0b0b0; /* Light grey border for hover and focus */
  background-color: #e0e0e0; /* Light grey background on hover */
}

/* Focused states for inputs and selects */
.form-item input:focus-visible,
.form-item .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.form-item .MuiSelect-root.Mui-focused {
  border-color: #b0b0b0; /* Consistent light grey border */
  box-shadow: none; /* Keep it clean without additional glow */
}

/* Dark mode styling for radio buttons and checkboxes */
.form-item .MuiRadio-root.Mui-checked,
.form-item .MuiCheckbox-root.Mui-checked {
  color: #b0b0b0; /* Light grey color for checked state */
}

/* Adjust button colors for better contrast in dark mode */
.form-item button.MuiButton-root {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid #b0b0b0; /* Light grey border */
}

/* Button hover effect */
.form-item button.MuiButton-root:hover {
  background-color: #b0b0b0; /* Light grey background on hover */
  color: var(--text-color); /* Keep text color consistent */
}