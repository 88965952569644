:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --header-background-color: #ffffff;
}

.App-header {
  background-color: var(--header-background-color);
  transition: background-color 0.3s ease;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.header-title {
  font-size: 2rem;
  margin: 10px 0;
  color: var(--text-color);
}

.header-subtitle {
  font-size: 1rem;
  margin: 5px 0;
  color: var(--text-color);
  opacity: 0.8; /* Makes the subtext slightly lighter for emphasis */
  text-align: center; /* Center aligns the subtitle */
}

.theme-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  transition: color 0.3s ease;
}


.App {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  min-height: 100vh;
}

.App-header {
  background-color: var(--header-background-color);
  transition: background-color 0.3s ease;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: auto;
  margin: 10px;
}


.App-content {
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App-link {
  color: #61dafb;
}

.App.dark {
  --background-color: #282c34;
  --text-color: #ffffff;
  --header-background-color: #333333;
}

.theme-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.dropdown-select {
  color: var(--text-color);
  background-color: var(--background-color);
}

.dropdown-label {
  color: var(--text-color);
}